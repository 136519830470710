.socialMediaIcon {
  font-size: 50px;
  cursor: pointer;
}

.socialMediaIcon[type='LINKEDIN'] {
  animation-name: socialMediaIconLinkedinAnimation;
  animation-delay: 0.5s;
  animation-duration: 1.6s;
  animation-direction: normal, reverse;
}
@keyframes socialMediaIconLinkedinAnimation {
  from {
    color: #000000;
    font-size: 50px;
  }
  to {
    color: #4376B2;
    font-size: 60px;
  }
}

.socialMediaIcon[type='XING'] {
  animation-name: socialMediaIconXingAnimation;
  animation-delay: 0.7s;
  animation-duration: 1.6s;
  animation-direction: normal, reverse;
}
@keyframes socialMediaIconXingAnimation {
  from {
    color: #000000;
    font-size: 50px;
  }
  to {
    color: #015A5F;
    font-size: 60px;
  }
}

.socialMediaIcon[type='WHATSAPP'] {
  animation-name: socialMediaIconWhatsappAnimation;
  animation-delay: 0.9s;
  animation-duration: 1.6s;
  animation-direction: normal, reverse;
}
@keyframes socialMediaIconWhatsappAnimation {
  from {
    color: #000000;
    font-size: 50px;
  }
  to {
    color: #3DBF4C;
    font-size: 60px;
  }
}

.socialMediaIcon[type='FACEBOOK'] {
  animation-name: socialMediaIconFacebookAnimation;
  animation-delay: 1.1s;
  animation-duration: 1.6s;
  animation-direction: normal, reverse;
}
@keyframes socialMediaIconFacebookAnimation {
  from {
    color: #000000;
    font-size: 50px;
  }
  to {
    color: #395590;
    font-size: 60px;
  }
}

.socialMediaIcon[type='INSTAGRAM'] {
  animation-name: socialMediaIconInstagramAnimation;
  animation-delay: 1.3s;
  animation-duration: 1.6s;
  animation-direction: normal, reverse;
}
@keyframes socialMediaIconInstagramAnimation {
  from {
    color: #000000;
    font-size: 50px;
  }
  to {
    color: #7B3AAC;
    font-size: 60px;
  }
}

.socialMediaIcon:hover {
  font-size: 60px;
  transition: font-size 0.2s linear;
}

.socialMediaIcon[type='LINKEDIN']:hover {
  color: #4376B2;
  transition: color 0.4s linear;
}
.socialMediaIcon[type='XING']:hover {
  color: #015A5F;
  transition: color 0.4s linear;
}
.socialMediaIcon[type='WHATSAPP']:hover {
  color: #3DBF4C;
  transition: color 0.4s linear;
}
.socialMediaIcon[type='FACEBOOK']:hover {
  color: #395590;
  transition: color 0.4s linear;
}
.socialMediaIcon[type='INSTAGRAM']:hover {
  color: #7B3AAC;
  transition: color 0.4s linear;
}

