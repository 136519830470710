.fadeIn {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 1.2s ease-out, transform 0.6s ease-out;
}

.fadeInInvisible[orientation='left'] {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
}

.fadeInInvisible[orientation='right'] {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
}

.fadeInInvisible[orientation='bottom'] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
}

.fadeInInvisible {
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 0.2s ease-out;
}