.zitatContainer {
  max-width: 640px;
  font-size: 24px;

  letter-spacing: 2px;
}

.zitatContainer:hover {
  color: #0171d0;
}

.item {
  padding: 10px;
  font-size: 18px;
  color: #FFFFFF;
}
.itemTop {
  font-weight: 700;
  padding: 10px 10px 20px 10px;
  background: #1876D2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.item:hover .itemTop {
  color: #e1e9ff;
}
.itemBottom {
  padding: 20px 10px 10px 10px;
  color: #000000;
  background: #e1e9ff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: background 0.4s linear;
}
.item:hover .itemBottom {
  background: #b9caff;
  transition: background 0.4s linear;
}