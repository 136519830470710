


.chatHeaderMessageImg {
    width: 30px;
    margin-left: -5px;
}

.chatHeaderName {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    line-height: 1.1;
}

.chatHeaderOnline {
    color: #03FF37;
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
    line-height: 1.1;
}

.closeIcon {
    color: red;
    font-size: 30px;
}.closeIcon:hover {
    color: darkred;
    cursor: pointer;
    transform: scale(1.05, 1.005);
}

.chatConversation {
    background-color: #E6F4E9;
    height: calc(60vh - 105px);
    overflow-x: auto;
}
.inputTextForm {
    padding: 10px;
}
.inputText {
    width: 100%;

}

.yourMessega {
    margin: 10px;
    margin-left: 50px;
    padding: 5px 10px 15px 5px;
    background-color: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: right;
}

.otherMessega {
    margin: 10px;
    margin-right: 50px;
    padding: 5px 5px 15px 10px;
    background-color: #CBFFD6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.messagaContent {
    font-weight: bold;
    font-size: 14px;
}
.timeContent {
    padding-top: 10px;
    font-size: 12px;
}


.closeBackground {
    background: #FB6962;
    margin-top: -7px;
    margin-right: -7px;
    width: 30px;
    height: 30px;
    padding: 7px 7px 7px 6px;
    border-radius: 45px;
}
.closeBackground:hover {
    cursor: pointer;
    scale: 1.05;
}

.sendBackground {
    background: #2fA88F;
    margin-top: -7px;
    margin-right: -7px;
    width: 30px;
    height: 30px;
    padding: 6px 7px 7px 8px;
    border-radius: 45px;
}
.sendBackground:hover {
    cursor: pointer;
    scale: 1.05;
}