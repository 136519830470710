.projectIcon {
  font-size: 120px;
  padding-top: 190px;
  padding-bottom: 120px;
  width: 100%;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes example {
  from {background-color: #abfdab;}
  to {background-color: #b2ffe8;}
}