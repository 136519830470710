:root {
  --venmediaColor-2: #00503a;
  --venmediaColor-1: #006749;
  --venmediaColor0: #00478d;
  --venmediaColor1: #0147b0;
  --venmediaColor2: #0125d9;
}



.venmediaButton {
  display: inline-block;
  padding: 28px 45px;
  box-shadow: 0 22px 84px -24px var(--venmediaColor1);

  background-color: var(--venmediaColor1);
  color: #FFFFFF;
  border: 1px solid var(--venmediaColor1);
  overflow: hidden;

  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  height: auto;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  cursor: pointer;
}

.venmediaButton:hover {
  background-color: var(--venmediaColor0);
}

body {
  background-image: url("/public/background/background.png");
  background-repeat: unset;
  background-position: center;
  background-attachment: fixed;
  background-size: 100%;
  font-family: 'DM Sans', sans-serif;
  font-size: clamp(2px, 2.5vw, 16px);
  letter-spacing: -0.5px;
  line-height: 1.5;
}

.app{
  font-family: 'DM Sans', sans-serif;
  font-size: clamp(2px, 2.5vw, 16px);
  letter-spacing: -0.5px;
  line-height: 1.5;
}
.appMargin {
  padding: 10px;
}

.datenschutzeinstellungenButton {
  display: inline-block;
  padding: 2px 45px;
  background-color: blue;
  color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid darkblue;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.04em;
  text-align: center;
  cursor: pointer;
}

.footer {
  color: #ffffff;
  background-color: #000000;
}

.wrapper {
  max-width: 70.25rem;
  margin-left: auto;
  margin-right: auto;
}
.wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  padding: 0em !important;
}

.grid {
  margin: 0px !important;
}


.ee-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}


.p-sidebar .p-sidebar-header {
  padding: 10px !important;
  background: #2fA88F;

  border-radius: 45px;
  margin-top: -45px;
  height: 90px;
  margin-left: -1px;
  margin-right: -1px;

}