.root{
    z-index: 1;
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: 300px;
    height: 70px;
    border-radius: 35px;
    color: #FFFFFF;
    line-height: 1.3;
    transition: transform 0.8s; /* Animation */

    background: #106c56;
    background: -moz-linear-gradient(145deg, #106c56 0%, #45d2b0 100%);
    background: -webkit-linear-gradient(145deg, #106c56 0%, #45d2b0 100%);
    background: linear-gradient(145deg, #106c56 0%, #45d2b0 100%);

}
.root:hover {
    cursor: pointer;
    transform: scale(1.03, 1.001)
}

.chatHeaderOnline {
    color: #03FF37;
    font-weight: bold;
    font-size: 10px;
}

.buttonStarten {
    background: #FFFFFF;
    border-radius: 35px;
    color: #106c56;
    font-size: 16px;
    padding: 2px 15px 2px 15px;
    margin: 4px 15px 0px 15px;
    font-weight: bold;
}