/* dm-sans-regular - latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/dm-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../public/fonts/dm-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../public/fonts/dm-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../public/fonts/dm-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../public/fonts/dm-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../public/fonts/dm-sans-v11-latin-regular.svg#DMSans') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}
