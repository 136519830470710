.root {
    position: fixed;
    width: 100%;
    background-color: #000000;
    opacity: 0.98;
    z-index: 9999;
    color: #ffffff;
}

.headerText{
    margin-top: -10px;
    font-size: 30px;
    font-weight: 700;
}

.headerColor{
    color: #1976d2;
}
.menuItem{
    float: right;
    padding: 10px;
    text-decoration: none;
    color: #FFFFFF;
    border-bottom: 3px solid #000000;
}
.menuItem:hover{
    border-bottom: 3px solid #1976d2;
}
.selectedMenuItem{
    border-bottom: 3px solid #1976d2;
}